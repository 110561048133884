











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question525',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
        language: 'fr',
      },
      options: [
        {
          text: 'A and C are diastereomers',
          value: 'ACdiastereoisomers',
        },
        {
          text: 'A and B are diastereomers',
          value: 'ABdiastereoisomers',
        },
        {
          text: 'A and C are enantiomers',
          value: 'ACenantiomers',
        },
        {
          text: 'A and B are mesos compounds',
          value: 'ABmeso',
        },
      ],
      optionsFr: [
        {
          text: 'A et C sont des diastéréoisomères',
          value: 'ACdiastereoisomers',
        },
        {
          text: 'A et B sont des diastéréoisomères',
          value: 'ABdiastereoisomers',
        },
        {
          text: 'A et C sont des énantiomères',
          value: 'ACenantiomers',
        },
        {
          text: 'A et B sont des composés mésos',
          value: 'ABmeso',
        },
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q525.png';
    },
  },
};
